<template>
  <div class="service-category-setting">
    <PageTitle title="商品類別設定" btn="新增" @btnClick="openCreateDialog = true" />

    <FiltersContainer>
      <el-input
        v-model="nameSearch"
        clearable
        placeholder="輸入類別名稱"
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-search"
        />
      </el-input>
    </FiltersContainer>

    <section>
      <p>目前商品種類總數：{{ productCategoryTotalCount }} / 合約方案商品種類上限：{{ categoryLimit }}</p>
      <ProgressBar :percentage="categoryLimitPercentage" />
    </section>

    <SmallCategoryTable :openCreateDialog="openCreateDialog" :nameSearch="nameSearch" :categoryCount.sync="productCategoryCount" @close="openCreateDialog = false" />
  </div>
</template>

<script>
import SmallCategoryTable from './components/SmallCategoryTable.vue'
import { mapGetters } from 'vuex'
import ProgressBar from '@/components/Progress/ProgressBar'
import {
  GetProductCategoryCount,
} from '@/api/ecommerce/category'
export default {
  name: 'ServiceCategorySetting',
  components: { SmallCategoryTable, ProgressBar },

  data: () => ({
    tab: 'big',
    loading: false,
    nameSearch: '',
    dialogType: '',
    openCreateDialog: false,
    productCategoryCount: 0,
    productCategoryTotalCount: 0,
  }),
  computed: {
    ...mapGetters([
      'userPlanLimit',
      'shop',
    ]),
    categoryLimit () {
      return this.userPlanLimit.ecommerceCategoryLimit
    },
    categoryLimitPercentage () {
      if (this.userPlanLimit.ecommerceCategoryLimit === 0) {
        return 0
      }
      const count = Math.round(this.productCategoryTotalCount * 100 / this.userPlanLimit.ecommerceCategoryLimit)
      return count > 100 ? 100 : count
    },
  },
  async mounted () {
    await this.getServiceCategoryTotalCount()
  },
  methods: {
    //= > 取得服務人員總數
    async getServiceCategoryTotalCount () {
      this.loading = true
      const [res, error] = await GetProductCategoryCount({
        shopId: this.shop,
        name: undefined,
      })
      this.loading = false
      if (error) return this.$message.error(error)
      this.productCategoryTotalCount = res.count
    },
  },
}
</script>

<style scoped lang="postcss">
.tab-btn {
  @apply pb-[10px] cursor-pointer;
}
.tab-activated {
  @apply border-b-[5px] border-primary-100;
}
</style>
