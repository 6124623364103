<template>
  <el-dialog
    :title="dialogTitle"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <section>
      <el-form
        ref="form"
        :model="formData"
        label-position="top"
        :rules="formRules"
      >
        <el-form-item label="圖片" prop="avatar">
          <UploadButton
            :img="formData.avatar"
            :isAvatar="true"
            @change="loadImg"
          />
        </el-form-item>

        <el-form-item label="名稱" prop="name">
          <el-input v-model="formData.name" placeholder="請輸入類別名稱" />
        </el-form-item>

        <el-form-item label="排序" prop="order">
          <el-input v-model="formData.order" placeholder="請輸入順序" />
        </el-form-item>
      </el-form>
    </section>

    <div slot="footer">
      <el-button
        plain
        @click="cancel"
      >
        返回
      </el-button>
      <el-button type="primary" @click="dialogConfirm">
        {{
          dialogType === 'create' ? '新增' : '儲存'
        }}
      </el-button>
    </div>

    <image-cropper
      v-if="uploadDialog"
      :image="formData.img"
      @uploaded="getImage"
      @close="uploadDialog = false"
    />
  </el-dialog>
</template>

<script>
import { noEmptyRules, isDigitRules, rangeRules } from '@/validation/index'
// import imageMixin from '@/mixin/image'
import UploadButton from '@/components/Button/UploadButton.vue'
import {
  CreateProductCategory,
  UpdateProductCategory,
} from '@/api/ecommerce/category'
import formUtils from '@/utils/form'
import ImageCropper from '@/components/ImageCropper.vue'
import { mapGetters } from 'vuex'
import { extractList, imgSrc } from '@/utils/helper'

export default {
  name: 'SmallCategoryCreateDialog',
  // mixins: [imageMixin],
  components: { ImageCropper, UploadButton },
  props: ['dialogTitle', 'dialogType', 'selectRow'],
  computed: {
    ...mapGetters(['shop']),
    productsList () {
      if (this.formData.services.length === 0) return []
      return extractList('id', this.formData.services)
    },
  },
  data: () => ({
    uploadDialog: false,
    avatarChanged: false,
    formData: {
      img: null,
      isPublic: true,
      avatar: null,
      name: '',
      order: 100,
      id: '',
      services: [],
    },

    formRules: {
      avatar: [noEmptyRules()],
      name: [noEmptyRules('請輸入類別名稱')],
      order: [noEmptyRules('請輸入排序'), isDigitRules(), rangeRules()],
    },
  }),

  mounted () {
    this.syncFormData()
  },
  methods: {

    getImage (data) {
      this.formData.avatar = data
      this.avatarChanged = true
      this.uploadDialog = false
    },

    loadImg (img) {
      this.formData.img = img
      this.uploadDialog = true
    },

    confirm () {
      this.$emit('confirm')
      this.$emit('close')
    },

    cancel () {
      this.resetForm()
      this.formData.services = []
      this.$emit('cancel')
      this.$emit('close')
    },

    syncFormData () {
      if (!this.selectRow) return
      this.formData.name = this.selectRow.name
      this.formData.order = this.selectRow.order
      this.formData.id = this.selectRow.id
      this.formData.isPublic = this.selectRow.isPublic
      this.formData.avatar = this.selectRow.Image
    },

    resetForm () {
      formUtils.resetForm(this.$refs.form)
      this.formData = {
        img: null,
        isPublic: true,
        avatar: null,
        name: '',
        order: 100,
        id: '',
        services: [],
      }
    },

    async checkForm () {
      return await formUtils.checkForm(this.$refs.form)
    },

    //= > 創建產品類別
    async createServiceCategory () {
      this.loading = true
      const [res, error] = await CreateProductCategory({
        shopId: this.shop,
        name: this.formData.name,
        order: this.formData.order,
        // isPublic: this.formData.isPublic,
        image: this.formData.avatar ? this.formData.avatar.id : undefined,
        ecommerceProducts: this.productsList,
      })
      this.loading = false
      if (error) return this.$message.error(error)
      this.$emit('refresh')
      this.$message.success('新增成功')
      this.$emit('close')
    },

    //= > 更新產品類別
    async updateServiceCategory () {
      this.loading = true
      const [res, error] = await UpdateProductCategory({
        shopId: this.selectRow.ShopId,
        id: this.selectRow.id,
        name: this.formData.name,
        order: this.formData.order,
        image: this.avatarChanged ? this.formData.avatar.id : undefined,
      })
      this.loading = false
      if (error) return this.$message.error(error)
      this.$emit('refresh')
      this.$message.success('更新成功')
      this.$emit('close')
    },

    async dialogConfirm () {
      if (!(await this.checkForm())) return
      const type = this.dialogType
      if (type === 'create') this.createServiceCategory()
      if (type === 'update') this.updateServiceCategory()
    },
  },
}
</script>

<style lang="postcss">
 .v-modal {
  z-index: 200 !important;
}
</style>
