<template>
  <div class="progress">
    <el-progress :text-inside="true" :stroke-width="20" :percentage="percentage" :color="colorList" />
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    percentage: {
      type: [String, Number],
      default: 0,
    },
  },
  data: () => ({
    colorList: [{
      color: 'var(--action)',
      percentage: 50,
    },
    {
      color: 'var(--warning)',
      percentage: 100,
    },
    {
      color: 'var(--danger)',
      percentage: 100,
    },
    ],
  }),
}
</script>

<style lang="postcss" scoped>
.progress {
  @apply mt-[4px];
}
</style>
